.Login {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
}

.TextValidator {
    width: 35%;
    text-align: center;
    display: block;
}

.Form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.FormElement {
    width: 100%;
}

@media (max-width: 500px) {
    .TextValidator {
        width: 65%;
    }
}
