.LoginControls {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    align-items: flex-start;
    height: 256px;
}

.Control {
    align-self: center;
    margin: 5px 20px;
    text-align: right;
    height: 100%;
}

.ControlRecherche {
    align-self: center;
    margin: 100px 20px;
    width: 100%;
}
