.footerTableRight {
    text-align: right;
}

.root {
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
    padding: 10px;
}

.rootTable {
    display: table-row;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
    padding: 10px;
}

.rootTable > div {
    display: table-cell;
}