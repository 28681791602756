.Header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    height: 10%;
}

.LogoSofia {
    text-align: center;
    align-self: center;
}

@media (max-width: 900px) {
    .LogoSofia {
        margin-left: 30px;
    }

    .HeaderBorder {
        box-shadow: 0 0 0 0;
    }
}

@media (min-width: 901px) {
    .Header {
        width: 100%;
        background: white;
    }

    .HeaderBorder {
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
        z-index: 10;
    }
}


