.TextValidator {
    width: 300px;
    text-align: center;
}

.form {
    width: 100%;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.submitButton {
    margin-top: 15px;
}
