.cardStyle {
    width: 100%;
    margin-bottom: 15px;
}

.cardHeader {
    background-color: antiquewhite;
    max-height: 40px;
}

.divHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardContent {
    min-height: 120px;
}
