@media (max-width: 900px) {
    .Menu {
        display: none;
    }
}

@media (min-width: 901px) {
    .Menu {
        display: flex;
    }

    .MenuList {
        display: flex;
        height: 100%;
    }
}

.MenuMobileOpen {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    transform: translateX(0);
}

.SubMenu {
    margin-top: 45px;
}
.MenuDeclarer {
    font-weight: bold;
    color: #c90039;
}
