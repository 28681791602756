.imgLogin {
    width: 100%;
    margin: 0 auto;
}

.LoginContainer {
    text-align: center;
    width: 100%;
    height: 100%;
}

.LoginPanelContainer {
    background-color: #F0F0F2;
    border-radius: 10px;
    /*height: 50%;*/
    margin: 20px auto 0;
    width: 60%;
}

.LoginControls {
    display: flex;
}

.LoginImage {
    height: 220px;
}