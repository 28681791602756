.RechercheAssujettiContainer {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
}

.RechercheAssujettiContainer > div > div {
    width: 100%;
}

.RechercheAssujettiSubmitButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.RechercheAssujettiSubmitButton {
    margin: 0 5px !important;
}

.bouton {
    background-color: #00BCD4 !important;
}

.bouton:hover {
    background-color: green !important;
    color: #FFF;
}
