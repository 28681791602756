.LayoutHeader {
    width: 100%;
}

.LayoutHeaderAdmin {
    width: 100%;
    padding-bottom: 150px;
}

.LayoutBodyContainer {
    width: 100%;
    min-height: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LayoutBodyCenter {
    width: 100%;
    flex: 1;
    display: flex;
}

.LayoutBodyTop {
    display: flex;
    align-items: center;
    padding: 5px 40px;
    width: 100%;
    justify-content: space-between;
    height: 8%;
}

@media (max-width: 1260px) {
    .LayoutBody {
        width: 100%;
    }
}