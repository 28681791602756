.FlagButton {
    align-self: flex-start;
    text-align: center;
}
.FlagButtonDesktop {
    align-self: flex-start;
    text-align: center;
}

.FlagButtonMobile {
    display:none;
}

@media (max-width: 900px) {
    .FlagButtonDesktop {
        display: none;
    }
    .FlagButtonMobile {
        align-self: flex-start;
        text-align: center;
        display: flex;
    }
}

.ControlsContainer {
    display: flex;
}

.ControlsContainerDisplayNone {
    display:none;
}
