.fournisseur {
    background-color: #d88700;
    padding: 20px;
    font-size: large;
}

.bibliotheque {
    background-color: #a3bf2a;
    padding: 20px;
    font-size: large;
}
